import {FaChevronLeft} from "react-icons/fa";
import useWindowSize from "@/hooks/useWindowSize";
import {isMobile, isTablet} from "react-device-detect";
import {ButtonHTMLAttributes, useContext, useEffect, useState} from "react";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {BiSolidDiscount} from "react-icons/bi";
import {HiOutlineArrowUturnLeft} from "react-icons/hi2";
import {BrandMapColorTheme} from "@devour/client";
import { BsCart3 } from "react-icons/bs";
import RestaurantSearchAddressButton from "@/pages/restaurants/components/RestaurantSearchAddressButton";


export interface CartProps {
    isShowingCartPanel: boolean;
    sumQuantity: number;
    toggleShowCartPanel: () => void;
}
interface Props {
    restaurantId?: string;
    showChevron?: boolean;
    sumQuantity?: number;
    handleBackClick?: () => void;
    cart?: CartProps;
    hideLocation?: boolean;
    showPromoLabel?: boolean;
    showOrderMoreButton?: boolean;
    title: React.ReactNode;
    description: React.ReactNode;
}

function BrandLandingOrderHeader(props: Props) {
    const {restaurantId, showChevron, title, description, showPromoLabel, handleBackClick, showOrderMoreButton} = props;
    const {slug} = useParams<{ slug: string }>();
    const {data: brandMap} = useGetBrandMap(slug);

    const {setBrandMapState, setSelectedRestaurant, selectedPromo: selectedPromoId} = useContext(BrandMapContext);
    const [selectedPromoCount, setSelectedPromoCount] = useState<number>(0);

    const windowSize = useWindowSize()[0];
    const isWindowMobileView = windowSize < 768;
    const isMobileView = isMobile && !isTablet || isWindowMobileView;
    const isTextDark = brandMap?.colorTheme === BrandMapColorTheme.DARK;

    const promoText = selectedPromoCount > 1 ? "Promos Applied" : "Promo Applied";
    const promotionText = selectedPromoCount > 1 ? "Promotions Applied" : "Promotion Applied";

    // Calculate the number of promotions that were applied for this restaurant
    useEffect(() => {
        if (!brandMap) return;

        if (showPromoLabel) {
            const allPromos = (brandMap.promos ?? []).concat(brandMap.mainPromo ?? []);
            let selectedPromoCount = 0;

            allPromos.forEach(promo => {
                // Check if the promotion is available for the business
                const isAvailable = (promo.isBrandMapAutoApplied || promo.id === selectedPromoId) &&
                    (!promo.businesses?.length || promo.businesses.includes(restaurantId));
                if (isAvailable) {
                    selectedPromoCount++;
                }
            });

            setSelectedPromoCount(selectedPromoCount);
        }
    }, [brandMap, selectedPromoId, restaurantId]);

    function defaultHandleBackClick() {
        setSelectedRestaurant(null);
        setBrandMapState(BrandMapStates.SELECT_LOCATION);
    }

    return (
        <>
            {/* HEADER */}
            <div className="brand-landing-order-header">
                <div className={classNames(
                    "brand-landing-order-header_title",
                    {
                        "order-more": showOrderMoreButton,
                        "has-challenge": !!brandMap?.challenge,
                    },
                )}>
                    <div className="brand-landing-order-header_title_text">
                        {showChevron &&
                            <button
                                className="chevron"
                                onClick={handleBackClick || defaultHandleBackClick}>
                                <FaChevronLeft size="1.5rem"/>
                            </button>
                        }

                        <div className="brand-landing-order-header_title_description">
                            <div className="brand-landing-order-header_title_promo">
                                <h3>{title}</h3>

                                {showPromoLabel &&
                                    <div className="brand-landing-order-header_label-container brand-map-promotions_pill-color">
                                        <BiSolidDiscount size="1.125rem"/>
                                        {selectedPromoCount} {isMobileView ? ` ${promoText}` : ` ${promotionText}`}
                                    </div>
                                }
                            </div>

                            <div className="brand-landing-order-header_description">{description}</div>
                        </div>
                    </div>
                    {brandMap?.challenge && !props.hideLocation &&
                        <div className="brand-landing-order-header_title_controls">
                            <div className="restaurant-promo-header_container_search-address">
                                <RestaurantSearchAddressButton redirect={false}/>
                            </div>
                            {props.cart && <FrameButton
                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                onClick={props.cart?.toggleShowCartPanel}
                                color={isTextDark ? "gray-light" : "ghost"}
                                size="narrow"
                                leftIcon={BsCart3}
                                className="restaurant-promo-header_container_btns-authenticated_cart"
                            >
                                {props.cart?.sumQuantity}
                            </FrameButton>}
                        </div>
                    }
                </div>

                {showOrderMoreButton && <FrameButton
                    color={brandMap?.colorTheme === BrandMapColorTheme.DARK ? "gray-light" : "ghost"}
                    size="large"
                    className="brand-landing-order-header_button"
                    onClick={() => setBrandMapState(BrandMapStates.NOT_INITIATED)}
                >
                    Order More
                    <HiOutlineArrowUturnLeft/>
                </FrameButton>
                }
            </div>
        </>
    );
}

export default BrandLandingOrderHeader;