import { BrandMapHtmlSection } from "@devour/client";
import { FC, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { createHash } from "crypto";
import { isDesktop, isTablet } from "react-device-detect";

interface props {
    section: BrandMapHtmlSection
}

export const BrandMapEmbeddableHtmlCol: FC<props> = ({section}) => {
    const ref = useRef<HTMLDivElement>(null);
    const cleanHtml = (content: string) => DOMPurify.sanitize(content, {
        ADD_TAGS: ["iframe"],
        ADD_ATTR: ["allowfullscreen", "scrolling", "target"],
    });

    useEffect(() => {
        if (ref.current && section.content) {
            ref.current.innerHTML = cleanHtml(section.content);
        }
    }, [section.content]);

    return (
        <div
            ref={ref}
            className="brand-map-embeddable-html_col"
            style={{width: `${isDesktop || isTablet ? section.widthPercentage : "100"}%`}}
        />
    );

};