import AssetImage from "@/components/AssetImage";
import FrameButton from "@/components/buttons/FrameButton";
import CircularProgressIndicator from "@/components/CircularProgress";
import ExpandableText from "@/components/ExpandableText";
import {useAsset} from "@/hooks/useAsset";
import {IStore} from "@/redux/defaultStore";
import {addError} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {ChallengeInfoResponse, ChallengesApi, OWReward, RewardResponseObject} from "@devour/client";
import {FC, useState} from "react";
import {isMobile} from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {BrandChallengeInstantRewardClaimedModal} from "./BrandChallengeInstantRewardClaimedModal";
import useGetOverwolfGameInfo from "@/hooks/useGetOverwolfGameInfo";
import {isColorDarkOrLight} from "@/utils/isColorDarkOrLight";
import classNames from "classnames";
import {lightenHexColor} from "@/utils/lightenHexColor";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import {BlinkBlur} from "react-loading-indicators";
import useRefreshRewardData from "@/hooks/useRefreshRewardData";

interface props {
    questReward: OWReward;
    challengeRes: ChallengeInfoResponse;
    onClaim: () => void;
}
export const BrandChallengeInstantRewardCard: FC<props> = ({ challengeRes, questReward, onClaim }) => {
    const dispatch = useDispatch();
    const rewardTitle = challengeRes.rewards[0]?.name;
    const { fullToken } = useSelector((store: IStore) => store.authStore);
    const challengeImage = isMobile ? challengeRes?.backgroundImageSmall : challengeRes?.backgroundImageLarge;
    const { data: challengeBackground } = useAsset((challengeRes ? challengeImage : "") as any);
    const rewardImage = challengeRes.rewards[0]?.image;
    const { data: game } = useGetOverwolfGameInfo(questReward.game);
    const isClaimed = challengeRes.isInstantRewardClaimed;
    const [isClaiming, setIsClaiming] = useState(false);
    const [claimRewardResponse, setClaimRewardResponse] = useState<RewardResponseObject>();
    const {refetch: refreshOWRewardData} = useRefreshRewardData();
    const isCompleted = (questReward?.userStat?.progress ?? 0) >= questReward.target;

    const handleCompleteQuest = async () => {
        setIsClaiming(true);
        try {
            const res = await new ChallengesApi(getConfig(fullToken)).claimChallengeReward({
                id: challengeRes.id,
            });
            onClaim();

            const prizeTypes = new Set(res.rewards?.map(reward => reward.rewardType));
            prizeTypes.forEach(type => {
                void refreshOWRewardData(type);
            });

            setClaimRewardResponse(res.rewards[0]);
        } catch (e) {
            dispatch(await addError(e));
        }
        setIsClaiming(false);
    };
    return (
        <>
            <FrameOneModal contentClassName="nft-promo-modal" isOpen={isClaiming} toggle={() => {}}>
                <div className="nft-promo-modal_loading_container">
                    <h4 className="nft-promo-modal_loading_cta">
                                    Hang Tight!
                    </h4>
                    <div className="nft-promo-modal_loading_description">Your reward is coming your way! Please give a couple of seconds. Thank you.</div>
                    <BlinkBlur color="rgba(170, 150, 239, 1)" size="medium" text="" textColor="" />
                </div>
            </FrameOneModal>
            {claimRewardResponse && challengeRes.rewards[0] && <BrandChallengeInstantRewardClaimedModal reward={challengeRes.rewards[0]} rewardResponse={claimRewardResponse} />}
            <div className="brand-challenge_instant-reward-card">
                <div className="brand-challenge_instant-reward-card_content">
                    <div
                        className="brand-challenge_instant-reward-card_content_background"
                        style={{
                            background: `linear-gradient(1deg, #212123 57.48%, rgba(33, 33, 35, 0.75) 75.42%, rgba(33, 33, 35, 0.00) 99.04%), url(${challengeBackground?.cdnUrl}) lightgray 50% / cover no-repeat`,
                        }}
                    ></div>
                    <div className="brand-challenge_instant-reward-card_content_header">
                        <AssetImage assetId={challengeRes.brandLogo} alt="Brand logo" />
                        <h5>{rewardTitle}</h5>
                    </div>
                    <hr />
                    <div className="brand-challenge_instant-reward-card_content_quest">
                        <CircularProgressIndicator
                            showGlow={isCompleted}
                            colorOverride={isColorDarkOrLight(challengeRes.brandColor) == "dark" ? lightenHexColor(challengeRes.brandColor, 40) : challengeRes.brandColor}
                            total={questReward.target}
                            current={
                                questReward.userStat?.progress
                                    ? Math.min(questReward.target, questReward.userStat.progress)
                                    : 0
                            }
                        />
                        <div className="brand-challenge_instant-reward-card_content_quest_info">
                            {game && (
                                <span className="brand-challenge_instant-reward-card_content_quest_info_game">
                                    Complete In: <strong>{game.name}</strong>
                                </span>
                            )}
                            <h6>{questReward.name}</h6>
                            <ExpandableText text={questReward.description} maxLines={2} />
                        </div>
                    </div>
                </div>
                <div className="brand-challenge_instant-reward-card_reward">
                    <div className={classNames("brand-challenge_instant-reward-card_reward_heading", isColorDarkOrLight(challengeRes.brandColor))} style={{
                        backgroundColor: challengeRes.brandColor,
                    }}>
                        <h5>Instant Reward</h5>
                    </div>
                    <AssetImage assetId={rewardImage} alt="Reward image" />
                    {isCompleted &&
                            <div className="brand-challenge_instant-reward-card_reward_claim">
                                <FrameButton
                                    size="normal"
                                    color={isClaimed ? "gray" : "purple-blue-gradient-shadow"}
                                    onClick={!isClaimed ? handleCompleteQuest : undefined}
                                >
                                    {isClaimed ? "Claimed" : "Claim"}
                                </FrameButton>
                            </div>
                    }
                </div>
            </div>
        </>
    );
};
