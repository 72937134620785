import { LootboxReward, NftsApi } from "@devour/client";
import getConfig from "@/utils/getConfig";
import { useQuery } from "@tanstack/react-query";

async function fetchDevourLootboxNftRewards(): Promise<LootboxReward> {
    return await new NftsApi(getConfig()).getDevourLootboxNftRewards();
}

function getDevourLootboxNftRewardsQuery() {
    return {
        queryKey: ["devour-lootbox-nft-rewards"],
        queryFn: () => fetchDevourLootboxNftRewards(),
        keepPreviousData: true,
        staleTime: 30 * 24 * 60 * 60000,
        refetchOnWindowFocus: false,
    } as const;
}

export function useGetDevourLootboxNftRewards() {
    return useQuery(getDevourLootboxNftRewardsQuery());
}