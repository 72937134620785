import {ReactElement} from "react";
import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {isDesktop, isTablet} from "react-device-detect";
import DOMPurify from "dompurify";
import { BrandMapEmbeddableHtmlCol } from "./BrandMapEmbeddableHtmlCol";

DOMPurify.addHook("afterSanitizeElements", (node) => {
    if (node.tagName === "IFRAME" || node.tagName === "IMG" || node.tagName === "VIDEO") {
        node.setAttribute("style", "width: 100%; max-width: 100%;");
    }
});

export function BrandMapEmbeddableHtml(): ReactElement {
    const {slug} = useParams<{ slug: string }>();
    const {data: brandMap} = useGetBrandMap(slug);

    const isRowEmpty = (row) => !row || row.every(item => !item?.content || !item?.widthPercentage);

    const isFirstRowEmpty = isRowEmpty(brandMap?.htmlSectionFirstRow);
    const isSecondRowEmpty = isRowEmpty(brandMap?.htmlSectionSecondRow);

    if (isFirstRowEmpty && isSecondRowEmpty) {
        return;
    }

    const cleanHtml = (content: string) => DOMPurify.sanitize(content, {
        ADD_TAGS: ["iframe"],
        ADD_ATTR: ["allowfullscreen", "scrolling", "target"],
    });


    return (
        <div className="brand-map-embeddable-html_container">
            {!isFirstRowEmpty && <div className="brand-map-embeddable-html">
                {brandMap.htmlSectionFirstRow.map((row, index) => {
                    if (index !== 0 && (!row.content || !row.widthPercentage)) {
                        return; // avoid rendering empty div for the 2nd section as well as the flex gap
                    }
                    return (
                        <BrandMapEmbeddableHtmlCol key={`${row}-${index}`} section={row} />
                    );
                })}
            </div>}

            {!isSecondRowEmpty && <div className="brand-map-embeddable-html">
                {brandMap.htmlSectionSecondRow.map((row, index) => {
                    if (index !== 0 && (!row.content || !row.widthPercentage)) {
                        return; // avoid rendering empty div for the 2nd section as well as the flex gap
                    }
                    return (
                        <BrandMapEmbeddableHtmlCol key={`${row}-${index}`} section={row} />
                    );
                })}
            </div>}
        </div>
    );
}