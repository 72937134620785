import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GuildsHeader from "@/pages/guilds/GuildsHeader";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import DevourTopNav from "@/components/DevourTopNav";
import GuildsExplore from "@/pages/guilds/GuildsExplore";
import GuildsSearchResults from "@/pages/guilds/GuildsSearchResults";
import {useSearchParams} from "react-router";
import classNames from "classnames";
import GuildBadges from "@/pages/guilds/GuildBadges";
import GuildBadgesViewAll from "@/pages/guilds/GuildBadgesViewAll";
import GuildsTopLeaderboard from "@/pages/guilds/GuildsTopLeaderboard";
import MyOtherGuildsViewAll from "@/pages/guilds/MyOtherGuildsViewAll";
import GuildMemberHeader from "@/pages/guilds/GuildMemberHeader";
import GuildMembershipInfo from "@/pages/guilds/GuildMembershipInfo";
import {useEffect} from "react";
import {useQueryClient} from "@tanstack/react-query";

export default function GuildsPage() {
    const [searchParams] = useSearchParams();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: guildOwnerships, refetch: refetchGuildOwnerships} = useGetGuildOwnerships(fullToken, true);
    const queryClient = useQueryClient();

    const currentGuild = guildOwnerships?.qualifiedGuilds.find(guild => guild.id === guildOwnerships.membership?.guild);
    useEffect(() => {
        void refetchGuildOwnerships();
        queryClient.invalidateQueries({ queryKey: ["top-guilds-leaderboard"]});
        queryClient.invalidateQueries({ queryKey: ["top-guild-leaders-leaderboard"]});
        queryClient.invalidateQueries({ queryKey: ["current-guild-top-members"]});
    }, []);

    if (searchParams.get("search")) {
        return <GuildsSearchResults />;
    } if (searchParams.get("view") === "badges") {
        return <GuildBadgesViewAll />;
    } if (searchParams.get("view") === "others") {
        return <MyOtherGuildsViewAll />;
    }

    return (
        <div className={classNames("guilds-page", {
            "guilds-page_default": !fullToken || guildOwnerships?.badges.length === 0,
        })}>
            <DevourTopNav text="Guilds"/>

            {currentGuild
                ? <GuildMemberHeader guild={currentGuild}/>
                : <GuildsHeader />
            }
            {guildOwnerships?.badges.length > 0 &&
                <>
                    {/* guild member => guild leaderboard */}
                    {currentGuild && <>
                        <GuildMembershipInfo/>
                        <div className="gofrens_divider"/>
                    </>}

                    {/* top leaderboard   */}
                    <GuildsTopLeaderboard/>
                    <div className="gofrens_divider"/>

                    {/* my badges    */}
                    <GuildBadges badges={guildOwnerships.badges}/>
                    <div className="gofrens_divider"/>
                </>
            }
            {/*  explore game guilds  */}
            <GuildsExplore />
        </div>
    );
}