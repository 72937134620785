import { FC, useEffect } from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import ThirdwebLogin from "@/pages/ThirdwebLogin";

interface props {
    isOpen: boolean;
    onClose: () => void;
}

const LoginModal: FC<props> = ({ isOpen, onClose }) => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const isOnOverwolf = useSelector((store: IStore) => store.metaStore.isOnOverwolf);

    useEffect(() => {
        if (isOpen && fullToken) {
            onClose();
        }
    }, [fullToken?.id, isOpen]);

    return (
        <FrameOneModal
            contentClassName="magic-login-modal"
            containerClassName="magic-login-modal-container"
            size={isOnOverwolf ? "xs2" : "sm2"}
            isOpen={isOpen}
            // toggle={onClose} Accidental closing the login modal will require the user to get a new code and invalid their previous one
        >
            <FrameModalHeader toggle={onClose}>
                {isOnOverwolf ? (
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogoWhite.webp`}
                        alt="Devour logo"
                        className="overwolf-landing-page_login-logo"
                    />
                ) : (
                    <img src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`} alt="" />
                )}
            </FrameModalHeader>
            <FrameModalBody>
                <ThirdwebLogin isOnDialog={true} />
            </FrameModalBody>
        </FrameOneModal>
    );
};

export default LoginModal;
