import FrameButton from "@/components/buttons/FrameButton";
import DevourTopNavUserData from "@/components/DevourTopNavUserData";
import { IStore } from "@/redux/defaultStore";
import { BrandMap, ChallengeInfoResponse } from "@devour/client";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoginOpen } from "@/redux/meta/metaActions";
import AssetImage from "@/components/AssetImage";
import {
    formatChallengeDateRangeFull,
} from "@/utils/formatChallengeDateRange";
import classNames from "classnames";
import { isColorDarkOrLight } from "@/utils/isColorDarkOrLight";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";
import useWindowSize from "@/hooks/useWindowSize";
import {getCurrentEstTime} from "@/pages/overwolf/components/OverwolfGoVipChallengesCard";
import { useGetUserProfile } from "@/hooks/useGetUserProfile";
import NftProfilePictureBadge from "@/components/NftProfilePictureBadge";
import BrandEmailModal from "@/components/brands/BrandEmailModal";
import BrandMapAccountDrawer from "@/components/brands/BrandMapAccountDrawer";

interface Props {
    challenge: ChallengeInfoResponse | undefined;
    hideTopBar?: boolean;
    brandMap?: BrandMap;
}

export const BrandChallengeHeading: FC<Props> = ({ challenge, hideTopBar, brandMap }) => {
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState(getCurrentEstTime());
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: getUserProfileData } = useGetUserProfile(fullToken);
    const user = getUserProfileData?.user;
    const [showAccountDrawer, setShowAccountDrawer] = useState<boolean>(false);
    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
    const screenSize = useWindowSize()[0];
    const navigate = useNavigate();
    const { isOnOverwolf } = useOverwolfInterop();

    useEffect(() => {
        const currentTimeIntervalKey = setInterval(() => setCurrentDate(getCurrentEstTime()), 1000);
        return () => clearInterval(currentTimeIntervalKey);
    }, []);

    const renderTimeRemaining = () => {
        const now = DateTime.fromMillis(currentDate).setZone("America/New_York");
        const future = DateTime.fromMillis(challenge.end).setZone("America/New_York");
        const diff = future.diff(now, ["days", "hours", "minutes"]).toObject();
        const days = Math.floor(diff.days);
        const hours = Math.floor(diff.hours);
        const minutes = Math.floor(diff.minutes);

        return (
            <>
                {days > 0 &&
                    <>
                        <strong>{days}</strong>d{" "}
                    </>
                }
                {(hours > 0 || days > 0) &&
                    <>
                        <strong>{hours}</strong>h{" "}
                    </>
                }
                <strong>{minutes}</strong>m
            </>
        );
    };

    const renderProfile = () => {
        if (!fullToken) {
            return (
                <img
                    onClick={() => setShowEmailModal(true)}
                    className="brand-challenge_top-bar_logo"
                    src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`}
                    alt=""
                />
            );
        }

        return (
            <div
                className="restaurant-promo-header_container_btns-authenticated_profile-pic"
                onClick={() => setShowAccountDrawer(true)}
            >
                {user?.gravatar && <img src={user?.gravatar} alt="User Profile" />}
                {user?.nftProfilePicture && <NftProfilePictureBadge size="sm" />}
            </div>
        );
    };

    const durationText = useMemo(() => {
        if (!challenge) {
            return "";
        }
        if (challenge.end <= currentDate) {
            return "Challenge has Ended";
        }
        if (challenge.start < currentDate) {
            return <>Challenge Ends: {renderTimeRemaining()}</>;
        }
        return `Challenge: ${formatChallengeDateRangeFull(challenge.start, challenge.end, screenSize >= 768)}`;
    }, [currentDate, challenge?.start, challenge?.end, screenSize]);
    return (
        <>
            {brandMap &&
                <>
                    <BrandEmailModal isOpen={showEmailModal} toggle={setShowEmailModal} brandMap={brandMap} />
                    <div className="brand-challenge_top-bar_account-drawer">
                        <BrandMapAccountDrawer isOpen={showAccountDrawer} toggle={() => setShowAccountDrawer(false)} />
                    </div>
                </>
            }
            {!hideTopBar && !isOnOverwolf &&
                <div className="brand-challenge_top-bar">
                    <div className="brand-challenge_top-bar_wrapper">
                        {!fullToken
                            ? <>
                                <img
                                    className="brand-challenge_top-bar_logo"
                                    src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`}
                                    alt=""
                                />
                                <FrameButton
                                    className="brand-challenge_top-bar_login-btn"
                                    onClick={() => {
                                        dispatch(toggleLoginOpen(true));
                                    }}
                                    color="white-outline"
                                    size="narrow"
                                >
                                    Login
                                </FrameButton>
                            </>
                            : <>
                                <img
                                    className="brand-challenge_top-bar_logo"
                                    src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`}
                                    alt=""
                                />
                                <DevourTopNavUserData
                                    onShowSignInToast={() => {}}
                                    dropdownAnchorElement=".brand-challenge_top-bar"
                                    avatarOnly
                                    hideWalletOnMobile={true}
                                />
                            </>
                        }
                    </div>
                </div>
            }
            {challenge &&
                <div
                    className="brand-challenge_header"
                    style={{
                        backgroundColor: challenge?.brandColor,
                    }}
                >
                    <div className="brand-challenge_header_wrapper">
                        <div className="brand-challenge_header_brand">
                            {isOnOverwolf &&
                                <FaArrowLeftLong
                                    className={isColorDarkOrLight(challenge.brandColor)}
                                    onClick={() => navigate("/overwolf-go-vip")}
                                />
                            }
                            {challenge?.brandLogo
                                ? <AssetImage
                                    className="brand-challenge_header_logo"
                                    assetId={challenge?.brandLogo as any}
                                    alt="brand-logo"
                                />
                                : <div />
                            }
                        </div>
                        <div className="brand-challenge_header_right">
                            <span
                                className={classNames(
                                    "brand-challenge_header_duration",
                                    isColorDarkOrLight(challenge.brandColor),
                                )}
                            >
                                {durationText}
                            </span>
                            {brandMap && renderProfile()}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
