import {useDispatch, useSelector} from "react-redux";
import useOverwolfInterop, { isOnOWKey } from "@/hooks/useOverwolfInterop";
import {logout} from "@/redux/auth/authActions";
import {useActiveWallet, useDisconnect} from "thirdweb/react";
import {Wallet} from "thirdweb/wallets";
import getConfig from "@/utils/getConfig";
import { TokensApi } from "@devour/client";
import {IStore} from "@/redux/defaultStore";

/**
 * Depending on where this hook is initialized, the `useActiveWallet()` may or may not be set.
 * Optionally pass the wallet instance to the `devourLogout()` function.
 */
export default function useOnLogout() {
    const { sendMessageToOW } = useOverwolfInterop();
    const dispatch = useDispatch();
    const { disconnect } = useDisconnect();
    const connectedWallet = useActiveWallet();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    function devourLogout(instancedWallet?: Wallet) {
        console.log("devour-logout");
        if (fullToken) {
            void new TokensApi(getConfig()).deleteToken({
                tokenBody: {
                    token: fullToken.token,
                },
            });
        }
        dispatch(logout());
        sendMessageToOW({type: "de:logout"});

        if (instancedWallet) {
            // console.log("disconnect instanced wallet", instancedWallet);
            disconnect(instancedWallet);
        }
        if (connectedWallet) {
            // console.log("disconnect connected wallet", connectedWallet);
            disconnect(connectedWallet);
        }

        // Delete storage data
        const isOnOverwolf = sessionStorage.getItem(isOnOWKey);
        localStorage.clear();
        sessionStorage.clear();
        if (isOnOverwolf) {
            sessionStorage.setItem(isOnOWKey, isOnOverwolf);
        }
    }

    return {devourLogout};
}
