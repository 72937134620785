import {store} from "@/redux";
import {IStore} from "@/redux/defaultStore";
import {setIsOnOverwolf} from "@/redux/meta/metaActions";
import { OverwolfUserInfo } from "@/types/OverwolfUserInfo";
import getConfig from "@/utils/getConfig";
import {GetUserResponse, Token, UsersApi} from "@devour/client";
import { clear } from "console";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { waitForOverwolfAuthHandshake } from "./waitForOverwolfAuthHandshake";

const eventMessageType = "devour-overwolf";

// Store all registered callbacks for Overwolf messages
const registeredCallbacks = new Set<(message: OverwolfMessage) => void>();

// Flag to track if Overwolf setup has been done
let isSetup = false;

export interface overwolfGameInfo {
    gameId: string;
    gameTitle: string;
    isGameRunning: boolean;
}

export type OverwolfMessage =
// happens when a game is launched on the overwolf application, if null there's no game running
    {
        type: "ow:game-info";
        payload: overwolfGameInfo | null;
    }
    // happens when overwolf want to send user info to the iframe
    | {
        type: "ow:user-info";
        payload: OverwolfUserInfo | null;
    }
    // happens when overwolf asks to navigate to a specific route
    | {
        type: "ow:navigate",
        payload: string | null;
    }
    // happens when overwolf asks to logout user when clicking the popup logout button
    | {
        type: "ow:logout",
    }
    // happens when overwolf asks to login user when clicking the popup login button
    | {
        type: "ow:login",
    }
    // happens when overwolf asks to reload the iframe
    | {
        type: "ow:reload";
    }
    // happens when XP has been rewarded to the user
    | {
        type: "ow:rewarded-xp";
    }
    // happens when the size of the window has changed
    | {
        type: "ow:size-status-change",
        payload: "collapsed" | "expanded"
    }
    // happens when a batch of events are logged
    | {
        type: "ow:game-events-logged";
    } // happens when overwolf app asks to edit profile picture
    | {
        type: "ow:edit-profile-picture";
    } // happens when overwolf app ask to open the help chat
    | {
        type: "ow:open-help";
    } // happens when overwolf app ask to open the menu sidebar
    | {
        type: "ow:open-menu";
    }
    | {
        type: "ow:guild-progress-updated";
    };


type DevourMessage =
// happens when the devour go app gets ready and has a user log in or when the user registers or sign in
    {
        type: "de:data";
        isLogin?: boolean;
        payload: {
            token: Token,
            userData: GetUserResponse
        };
    } |
    // happens when the user logs out to wipe user-related data on overwolf
    {
        type: "de:logout";
        isOnInitialization?: boolean;
    } |
    {
        type: "de:path-changed";
        payload: string;
    }
    // happens when devour app is ready app
    | {
        type: "de:ready";
    } |
    {
        type: "de:request-game-info";
    } |
    {
        type: "de:update-user";
        payload: {
            userData: GetUserResponse
        };
    };

/**
 * This is the type used for every message send to one app to another
 * de: ->  Devour app messages
 * ow: -> Overwolf app messages
 */
export const isOnOWKey = "isOnOverwolf";

export const sendMessageToOW = (message: DevourMessage) => {
    if (store.getState().metaStore.isOnOverwolf) {
        window.parent.postMessage({
            type: eventMessageType,
            payload: message,
        }, "*");
    }
};

export type SendMessageToOW = (message: DevourMessage) => void;

const useOverwolfInterop = (onEventReceived?: (message: OverwolfMessage) => void) => {
    const dispatch = useDispatch();
    const isOnOverwolf = useSelector((store: IStore) => store.metaStore.isOnOverwolf);

    // Add callback to the set of registered callbacks
    useEffect(() => {
        if (onEventReceived) {
            registeredCallbacks.add(onEventReceived);
            return () => {
                registeredCallbacks.delete(onEventReceived); // Remove callback on unmount
            };
        }
    }, [onEventReceived]);

    const setupOverwolf = () => {
        if (isSetup) return; // Prevent re-setup
        isSetup = true;

        const urlParams = new URLSearchParams(new URL(window.location.href).search);
        if (urlParams.get(isOnOWKey) === "false") {
            window.sessionStorage.setItem(isOnOWKey, "false");
        } else if (urlParams.get(isOnOWKey) === "true" || window.sessionStorage.getItem(isOnOWKey) === "true" || window.navigator.userAgent.includes("Overwolf")) {
            dispatch(setIsOnOverwolf(true));
            document.documentElement.setAttribute("data-is-on-overwolf", "true");
            window.sessionStorage.setItem(isOnOWKey, "true");
            const storeToken = store.getState().authStore.fullToken;
            // send a message to overwolf when the iframe is clicked to close dropdowns & modals
            window.addEventListener("click", () => {
                window.parent.postMessage("iframe-clicked", "*");
            });

            // Add the message event listener once
            window.addEventListener("message", (event: MessageEvent) => {
                if (event?.data?.type === eventMessageType) {
                    const data: OverwolfMessage = event.data.payload;
                    // Call all registered callbacks
                    registeredCallbacks.forEach((callback) => {
                        callback(data);
                    });
                }
            });

            if (!storeToken) {
                sendMessageToOW({type: "de:logout", isOnInitialization: true});
                setTimeout(() => {
                    sendMessageToOW({type: "de:ready"});
                }, 500);
                return;
            }
            new UsersApi(getConfig(storeToken)).getProfile()
                .then(async (userRes) => {
                    /*
                     * This handshake will ensure that the OW app has recieved / set the user data
                     * before devourgo requests info about the running game.
                     * (i.e. auth data MUST be set before de:ready is sent form devourgo)
                     */

                    /*
                     * Initially wanted to do setup the handshake as a promise, where the listener
                     * would resolve the promise, but settled on this to avoid race conditions where we
                     * send de:data before the handshake listener is setup.
                     */
                    waitForOverwolfAuthHandshake(storeToken, userRes, sendMessageToOW);
                })
                .catch((e) => {
                    console.error("Error getting user profile", e);
                    sendMessageToOW({type: "de:logout", isOnInitialization: true});
                });
        }
    };

    return {
        setupOverwolf,
        sendMessageToOW,
        isOnOverwolf,
    };

};

export default useOverwolfInterop;
