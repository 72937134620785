import {
    OnboardingApi,
    UsersApi,
    ThirdwebApi,
    Token,
    GetUserResponse
} from "@devour/client";
import useOverwolfInterop, { SendMessageToOW } from "./useOverwolfInterop";

export const waitForOverwolfAuthHandshake = (token: Token, userData: GetUserResponse, sendMessageToOW: SendMessageToOW, isFreshLogin: boolean=false) => {
        /*
        * This handshake will ensure that the OW app has recieved / set the user data
        * before devourgo requests info about the running game.
        * (i.e. auth data MUST be set before de:ready is sent form devourgo)
        */

       /*
        * Initially wanted to do setup the handshake as a promise, where the listener
        * would resolve the promise, but settled on this to avoid race conditions where we
        * send de:data before the handshake listener is setup.
        */

    let handshakeRecieved = false;
    const handshakeListener = (event: MessageEvent) => {
        if (event.data?.payload?.type === "ow:login-handshake") {
            handshakeRecieved = true;
            window.removeEventListener("message", handshakeListener);
        }
    };
    window.addEventListener("message", handshakeListener);
    // Will send data until handshake is recieved or 2 seconds have passed
    const dataInterval = setInterval(() => {
        sendMessageToOW({type: "de:data", payload: {token: token, userData }, isLogin: isFreshLogin});
    }, 100);
    const killIntervalAt = new Date().getTime() + 2000;
    const intervalId = setInterval(() => {
        if (handshakeRecieved) {
            sendMessageToOW({type: "de:ready"});
            clearInterval(dataInterval);
            clearInterval(intervalId);
        } else if (new Date().getTime() > killIntervalAt) {
            clearInterval(intervalId);
            clearInterval(dataInterval);
            sendMessageToOW({type: "de:ready"});
            window.removeEventListener("message", handshakeListener);
        }
    }, 100);
}