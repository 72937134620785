import {useContext, useEffect} from "react";
import Checkout from "@/pages/Checkout";
import {isMobile, isTablet} from "react-device-detect";
import {BrandMapContext} from "@/pages/brandMap/context/BrandMapContext";
import BrandLandingOrderHeader from "@/components/brands/BrandLandingOrderHeader";
import useWindowSize from "@/hooks/useWindowSize";
import BrandOrderMobileDrawer from "@/components/brands/BrandOrderMobileDrawer";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {BrandMap, BrandMapColorTheme} from "@devour/client";

interface Props {
    restaurantId: string;
    menuOrderId: string;
    toggleMobileDrawer: () => void;
    showMobileDrawer: boolean;
    brandMap: BrandMap;
}

const BrandLandingRestaurantMenuCheckout = (props: Props) => {
    const {
        showMobileDrawer,
        toggleMobileDrawer,
        menuOrderId,
        brandMap,
    } = props;
    const {brandMapState} = useContext(BrandMapContext);
    const windowSize = useWindowSize()[0];
    const isWindowMobileView = windowSize < 768;
    const isMobileView = isMobile && !isTablet || isWindowMobileView;
    const isBrandMapColorThemeLight = brandMap?.colorTheme === BrandMapColorTheme.LIGHT;

    /**
     * Selectively apply system dark mode theme to certain elements if the brand map color theme is light
     */
    useEffect(() => {
        if (brandMap?.colorTheme && isBrandMapColorThemeLight) {
            // For order success page
            const brandRestaurantContainer = document.querySelector(".brand-map-restaurant_container");

            // For restaurant menu page and checkout
            const brandRestaurant = document.querySelector(".brand-map-restaurant");

            if (brandRestaurantContainer) {
                brandRestaurantContainer.setAttribute("data-theme", "dark");
            }

            if (brandRestaurant) {
                brandRestaurant.setAttribute("data-theme", "dark");
            }
        }
    }, [brandMap?.colorTheme, brandMapState, showMobileDrawer, isMobileView]);


    if (!menuOrderId) {
        return null;
    }

    return (
        <>
            {isMobileView && <BrandOrderMobileDrawer
                toggle={toggleMobileDrawer}
                isOpen={showMobileDrawer}
                content={<div className="brand-map-restaurant_container is-mobile"><Checkout/></div>}
            />
            }

            <div
                className={classNames("restaurant-map-landing_checkout brand-map-restaurant restaurant-map-landing_ordering_step", {
                    "is-mobile": isMobileView,
                })}
            >

                <BrandLandingOrderHeader
                    title={isMobileView ? "ORDER" : "ORDER NOW"}
                    description={"Add items to your cart and continue to checkout."}
                    showChevron={true}
                    showPromoLabel={true}
                    hideLocation={true}
                    restaurantId={props.restaurantId}
                />

                {isMobileView &&
                        <>
                            <FrameButton
                                color={isBrandMapColorThemeLight ? "brand-dark" : "brand-light"}
                                size="large"
                                className="brand-map-restaurant_mobile_button"
                                onClick={() => toggleMobileDrawer()}
                            >
                                Checkout
                            </FrameButton>
                        </>
                }

                {!isMobileView && <div className="brand-map-restaurant_container"><Checkout/></div>}
            </div>
        </>
    );
};

export default BrandLandingRestaurantMenuCheckout;